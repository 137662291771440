var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('v-tabs',{attrs:{"align-with-title":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"agenda"},[_vm._v(" Agenda ")]),_c('v-tab',{key:"absensi"},[_vm._v(" Absensi ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"agenda"},[_c('v-card',[_c('v-card-text',[_c('router-link',{attrs:{"to":{
							name: 'PesertaPelatihanPreTest',
							params: { id_pelatihan: _vm.id_pelatihan },
						}}},[_c('v-btn',{staticClass:"mb-4",attrs:{"block":"","color":"primary","dark":""}},[_vm._v("Pre Test")])],1),_c('ul',_vm._l((_vm.table.items),function(item,index){return _c('li',{key:item.id},[_c('b',[_vm._v("Hari ke "+_vm._s(index + 1)+", "+_vm._s(_vm.formatDate(item.tanggal)))]),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((item.materis),function(itemMateri,indexMateri){return _c('tr',{key:itemMateri.id},[_c('td',[(itemMateri.tipe == 'MATERI')?_c('v-chip',{attrs:{"x-small":"","color":"primary"}},[_vm._v(_vm._s(itemMateri.tipe))]):(itemMateri.tipe == 'TUGAS')?_c('v-chip',{attrs:{"x-small":"","color":"warning"}},[_vm._v(_vm._s(itemMateri.tipe))]):(itemMateri.tipe == 'EVALUASI NARASUMBER' || itemMateri.tipe == 'EVALUASI PENYELENGGARA')?_c('v-chip',{attrs:{"x-small":"","color":"success"}},[_vm._v(_vm._s(itemMateri.tipe))]):(itemMateri.tipe == 'LINK')?_c('v-chip',{attrs:{"x-small":"","color":"info"}},[_vm._v(_vm._s(itemMateri.tipe))]):_vm._e(),_vm._v(" "+_vm._s(itemMateri.nama)+" ")],1),_c('td',[(itemMateri.file_url == '')?_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiLock)+" ")]):_c('span',[(itemMateri.tipe == 'MATERI')?_c('a',{staticClass:"mr-2",attrs:{"href":itemMateri.file_url,"target":"_blank"}},[_vm._v(" Lihat ")]):(itemMateri.tipe == 'TUGAS')?_c('router-link',{staticClass:"mr-2",attrs:{"to":{
																name: 'AdminPelatihanTugas',
																params: { id_pelatihan: _vm.id_pelatihan, id_materi: itemMateri.id, hari_ke: index + 1 },
															}}},[_vm._v(" Lihat ")]):_vm._e(),(itemMateri.tipe == 'LINK')?_c('a',{staticClass:"mr-2",attrs:{"href":itemMateri.aplikasi_url,"target":"_blank"}},[_vm._v(" Lihat ")]):(itemMateri.tipe == 'EVALUASI NARASUMBER' || itemMateri.tipe == 'EVALUASI PENYELENGGARA')?_c('router-link',{staticClass:"mr-2",attrs:{"to":{
																name: 'PesertaPelatihanEvaluasi',
																params: { id_pelatihan: _vm.id_pelatihan, id_materi: itemMateri.id, hari_ke: index + 1 },
															}}},[_vm._v(" Lihat ")]):_vm._e()],1)],1)])}),0)]},proxy:true}],null,true)})],1)}),0),_c('router-link',{attrs:{"to":{
							name: 'PesertaPelatihanPostTest',
							params: { id_pelatihan: _vm.id_pelatihan },
						}}},[_c('v-btn',{staticClass:"mb-4",attrs:{"block":"","color":"primary","dark":""}},[_vm._v("Post Test")])],1)],1)],1)],1),_c('v-tab-item',{key:"absensi"},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Daftar Absensi "),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","x-small":""},on:{"click":function($event){return _vm.getAbsensis()}}},[_vm._v("Reload")])],1),_c('v-card-text',[_c('ul',[_c('li',[_vm._v("Lakukan absensi setiap hari selama terdapat agenda pelatihan di hari tersebut")]),_c('li',[_vm._v("Tanggal, Jam Masuk dan Jam Pulang otomatis saat anda klik tombol Absen masuk dan Absen Pulang")])]),_c('v-btn',{attrs:{"color":"success","x-small":""},on:{"click":function($event){return _vm.addAbsensi('masuk')}}},[_vm._v("Absen Masuk")]),_c('v-btn',{staticClass:"ml-1",attrs:{"color":"primary","x-small":""},on:{"click":function($event){return _vm.addAbsensi('pulang')}}},[_vm._v("Absen Pulang")]),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("No")]),_c('th',[_vm._v("Tanggal")]),_c('th',[_vm._v("Masuk")]),_c('th',[_vm._v("Pulang")])])]),_c('tbody',_vm._l((_vm.tableAbsensi.items),function(item,key){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(key + 1))]),_c('td',[_vm._v(_vm._s(_vm.formatDate(item.tanggal)))]),_c('td',[_vm._v(_vm._s(_vm.formatTime(item.waktu_masuk)))]),_c('td',[_vm._v(_vm._s(_vm.formatTime(item.waktu_pulang)))])])}),0)]},proxy:true}])})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',[_c('v-card-text',[_c('h2',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(_vm.pelatihan.nama))]),_c('h3',[_vm._v("Detail Stakeholder Pelatihan:")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.pelatihan.detail_stakeholder)}})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }