<template>
	<v-row>
		<v-col cols="7">
			<v-tabs v-model="tab" align-with-title>
				<!-- <v-tabs-slider color="yellow"></v-tabs-slider> -->
				<v-tab key="agenda">
					Agenda
				</v-tab>
				<v-tab key="absensi">
					Absensi
				</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab">
				<v-tab-item key="agenda">
					<v-card>
						<v-card-text>
							<router-link :to="{
								name: 'PesertaPelatihanPreTest',
								params: { id_pelatihan: id_pelatihan },
							}">
								<v-btn block color="primary" class="mb-4" dark>Pre Test</v-btn>
							</router-link>
							<ul>
								<li v-for="(item, index) in table.items" :key="item.id">
									<b>Hari ke {{ index + 1 }}, {{ formatDate(item.tanggal) }}</b>
									<v-simple-table dense>
										<template v-slot:default>
											<tbody>
												<tr v-for="(itemMateri, indexMateri) in item.materis"
													:key="itemMateri.id">
													<td>
														<v-chip v-if="itemMateri.tipe == 'MATERI'" x-small
															color="primary">{{
																itemMateri.tipe }}</v-chip>
														<v-chip v-else-if="itemMateri.tipe == 'TUGAS'" x-small
															color="warning">{{
																itemMateri.tipe }}</v-chip>
														<v-chip
															v-else-if="itemMateri.tipe == 'EVALUASI NARASUMBER' || itemMateri.tipe == 'EVALUASI PENYELENGGARA'"
															x-small color="success">{{
																itemMateri.tipe }}</v-chip>
														<v-chip v-else-if="itemMateri.tipe == 'LINK'" x-small
															color="info">{{
																itemMateri.tipe }}</v-chip>
														{{ itemMateri.nama }}
													</td>
													<td>
														<v-icon v-if="itemMateri.file_url == ''" small>
															{{ icons.mdiLock }}
														</v-icon>
														<span v-else>
															<a :href="itemMateri.file_url" class="mr-2" target="_blank"
																v-if="itemMateri.tipe == 'MATERI'">
																Lihat
															</a>
															<router-link class="mr-2"
																v-else-if="itemMateri.tipe == 'TUGAS'" :to="{
																	name: 'AdminPelatihanTugas',
																	params: { id_pelatihan: id_pelatihan, id_materi: itemMateri.id, hari_ke: index + 1 },
																}">
																Lihat
															</router-link>
															<a v-if="itemMateri.tipe == 'LINK'"
																:href="itemMateri.aplikasi_url" class="mr-2"
																target="_blank">
																Lihat
															</a>
															<router-link class="mr-2"
																v-else-if="itemMateri.tipe == 'EVALUASI NARASUMBER' || itemMateri.tipe == 'EVALUASI PENYELENGGARA'"
																:to="{
																	name: 'PesertaPelatihanEvaluasi',
																	params: { id_pelatihan: id_pelatihan, id_materi: itemMateri.id, hari_ke: index + 1 },
																}">
																Lihat
															</router-link>
														</span>
													</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
								</li>
							</ul>
							<router-link :to="{
								name: 'PesertaPelatihanPostTest',
								params: { id_pelatihan: id_pelatihan },
							}">
								<v-btn block color="primary" class="mb-4" dark>Post Test</v-btn>
							</router-link>
						</v-card-text>
					</v-card>
				</v-tab-item>
				<v-tab-item key="absensi">
					<v-card>
						<v-card-title primary-title>
							Daftar Absensi <v-btn class="ml-2" color="primary" x-small
								@click="getAbsensis()">Reload</v-btn>
						</v-card-title>
						<v-card-text>
							<ul>
								<li>Lakukan absensi setiap hari selama terdapat agenda pelatihan di hari tersebut</li>
								<li>Tanggal, Jam Masuk dan Jam Pulang otomatis saat anda klik tombol Absen masuk dan
									Absen Pulang</li>
							</ul>
							<v-btn color="success" x-small @click="addAbsensi('masuk')">Absen Masuk</v-btn>
							<v-btn class="ml-1" color="primary" x-small @click="addAbsensi('pulang')">Absen
								Pulang</v-btn>
							<v-simple-table dense>
								<template v-slot:default>
									<thead>
										<tr>
											<th>No</th>
											<th>Tanggal</th>
											<th>Masuk</th>
											<th>Pulang</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, key) in tableAbsensi.items" :key="item.id">
											<td>{{ key + 1 }}</td>
											<td>{{ formatDate(item.tanggal) }}</td>
											<td>{{ formatTime(item.waktu_masuk) }}</td>
											<td>{{ formatTime(item.waktu_pulang) }}</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-tab-item>
			</v-tabs-items>
		</v-col>
		<v-col cols="5">
			<v-card>
				<v-card-text>
					<h2 style="text-transform: capitalize;">{{ pelatihan.nama }}</h2>
					<h3>Detail Stakeholder Pelatihan:</h3>
					<div v-html="pelatihan.detail_stakeholder"></div>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiEye, mdiPencil, mdiMagnify, mdiPlus, mdiTrashCan, mdiLock } from '@mdi/js'

export default {
	components: {
	},
	data() {
		return {
			id_pelatihan: this.$route.params.id,
			pelatihan: {},
			icons: {
				mdiEye,
				mdiPencil,
				mdiMagnify, mdiPlus, mdiTrashCan, mdiLock
			},
			tableOptions: {},
			table: {
				items: []
			},
			tab: null,
			tablePeserta: {
				items: [],
				selected: [],
				selectedAll: false,
				save_loading: false,
			},
			tableAbsensi: {
				items: [],
			}
		}
	},
	watch: {
	},
	computed: {
	},
	created() {
		this.getPelatihan()
		this.getAgendasPeserta()
		this.getAbsensis()
	},
	methods: {
		getPelatihan() {
			const fmData = new FormData()
			fmData.append('id_pelatihan', this.id_pelatihan)
			axiosPostAuth('api/Pelatihan/getPelatihan', fmData)
				.then(response => {
					this.pelatihan = response.data
				})
				.catch(error => {
					alert(error)
				})
		},
		getAgendasPeserta() {
			const fmData = new FormData()
			fmData.append('id_pelatihan', this.id_pelatihan)
			axiosPostAuth('api/Pelatihan/getAgendasPeserta', fmData)
				.then(response => {
					this.table.items = response.data
				})
				.catch(error => {
					alert(error)
				})
		},
		addAbsensi(aksi) {
			if (aksi == 'pulang') {
				if (!confirm("Apakah yakin akan absen pulang sekarang?")) {
					return
				}
			}
			const fmData = new FormData()
			fmData.append('aksi', aksi)
			fmData.append('id_pelatihan', this.id_pelatihan)
			axiosPostAuth('api/Pelatihan/addAbsensi', fmData)
				.then(response => {
					if (response.code === 200) {
						alert(response.message)
						this.getAbsensis()
					}
					else {
						alert(response.message)
					}
				})
				.catch(error => {
					alert(error)
				})
		},
		getAbsensis() {
			const fmData = new FormData()
			fmData.append('id_pelatihan', this.id_pelatihan)
			axiosPostAuth('api/Pelatihan/getAbsensis', fmData)
				.then(response => {
					if (response.code === 200) {
						this.tableAbsensi.items = response.data
					}
					else {
						alert(response.message)
					}
				})
				.catch(error => {
					alert(error)
				})
		}

	}
}
</script>